<template>
  <v-main>
    <v-btn style="float: right; margin: 15px;" to="/home" class="closeexp adminlargebutton"
      >Hem</v-btn
    >
    <v-container style="max-width: 600px;">
      <div class="py-8 text-center">
        <img src="./../assets/Hubblaloggo.png" class="headimg"/>
      </div>
      <v-layout align-center justify-center mt-5>
            <v-card class="px-4 rounded-lg">
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit.prevent="Register">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.firstname"
                        label="Förnamn"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Efternamn"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.regionId"
                        label="Region"
                        :items="dialoglists.regions"
                        item-text="regionName"
                        item-value="id"
                        :rules="[rules.required]"
                        required
                      ></v-select>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        class="loginbtncr"
                        @click="Register()"
                        >Registrera skapare</v-btn
                      >
                      <v-btn style="float: right;" text to="/integritypolicyc" class="integrate">Integritetsmeddelande</v-btn>
                    </v-col>
                  </v-row>
                  <v-alert v-if="info" type="success">{{ info }}</v-alert>
                </v-form>
              </v-card-text>
            </v-card>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";

export default {
  name: "RegisterPlayerToCreator",
  data() {
    return {
      userinfo: {
        email: "",
        password: "",
        usertype: "",
      },
      info: "",
      editedItem: {},
      tableItems: [],
      dialog: true,
      valid: null, 
      tab: 0,
      dialoglists: {},
      tabs: [
        { name: "Logga in", icon: "mdi-account" },
        { name: "Skapa konto", icon: "mdi-account-outline" },
      ],
      loading: false,
      rules: {
        required: (value) => !!value || "",
        min: (v) => v.length >= 4 || "Minst 4 tecken",
      },
      showpassword: false,
    };
  },
  created() {
    this.getRegions();
    this.newitem();
  },
  methods: {
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    getRegions() {
      HTTP.get("/token/getregions").then((response) => {
        this.dialoglists = response.data;
      });
    },
    newitem() {
      this.editedItem = {
        firstname: null,
        lastname: null,
        regionId: null,
        email: this.$store.getters.username,
      };
      this.editItem(this.editedItem);
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.showpassword = false;
      this.dialog = true;
    },
    Register() {
      var self = this;
      this.$refs.form.validate();
      if(!this.valid) {
        this.$root.$okconfirm
        .open("", "Vänligen fyll i de rödmarkerade fälten i formuläret", { color: "red" })
        return
      }
      HTTP.post("/token/create/creator", self.editedItem)
        .then((response) => {
          if (self.editedItem.id == 0) {
            self.editedItem.id = response.data;
            self.tableItems.push(this.editedItem);
          }
          this.$router.push({ path: "/experiences" });
          window.location.reload();
        })
        .catch((e) => {
          self.$store.dispatch("showError", "Fel vid sparning: " + e.response.data);
          this.$refs.form.Register();
        });
    },
  },
};
</script>

<style lang="css">
.loginbtncr {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  background-color: #69c5e4 !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

.loginbtncr:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}

.h5headline {
  color: #7030a0;
  font-size: 20px;
  font-weight: bold;
}
p,
li {
  color: black;
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 30px;
  }
}
</style>

