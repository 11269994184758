<template>

  <v-app>
     <router-view v-if="checkUserRoleCreator()" class="creatorbackground"></router-view>
     <router-view v-else class="adminbackground"></router-view>
    <v-dialog v-model="errordialog" persistent width="500">
      <v-card class="pa-4">
          <v-card-title class="headline">Meddelande</v-card-title>
          <v-card-text><v-icon large color="red">mdi-alert-circle</v-icon> {{errormessage}}</v-card-text>
          <v-card-actions class="justify-center">
            <v-btn tile @click="clearerror()">Ok</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  <confirm ref="confirm"></confirm>
  <okconfirm ref="okconfirm"></okconfirm>
  <deniedmessage ref="deniedmessage"></deniedmessage>
  </v-app>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import confirm from '@/components/confirm'
import okconfirm from '@/components/okconfirm'
import deniedmessage from '@/components/deniedmessage'
export default {
  name: 'App',

  components: { confirm, deniedmessage, okconfirm },
  data () {
    return {
      adminRole: "0",
    }
  },
  mounted () {
    this.$root.$confirm = this.$refs.confirm
    this.$root.$okconfirm = this.$refs.okconfirm
    this.$root.$deniedmessage = this.$refs.deniedmessage
  },
  created () {
    this.checkCurrentLogin()
    this.$store.dispatch('initStorage')
  },
  computed: {
    ...mapGetters([
      'errordialog',
      'errormessage',
      'isauthorized',
      'bearertoken',      
    ])
  },
  methods: {
    checkCurrentLogin () {
      if (!this.$router.currentRoute.path.startsWith('/m/') 
      && !this.$router.currentRoute.path.startsWith('/changepassword') 
      && !this.$router.currentRoute.path.startsWith('/upplevelser') 
      && !this.$router.currentRoute.path.startsWith('/home/') 
      && !this.$router.currentRoute.path.startsWith('/integritypolicy') 
      && !this.$router.currentRoute.path.startsWith('/register') 
      && !this.$router.currentRoute.path.startsWith('/deleteuseraccount')
      && !this.$router.currentRoute.path.startsWith('/userdeleted')
      && !this.$router.currentRoute.path.startsWith('/recoverpassword')) {
        if (HTTP.defaults.headers.common['Authorization'] === undefined) {
          HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
        }
        HTTP.get('/account/checkauthorization')
          .catch(e => {
            this.$store.dispatch('saveAuthorization', false)
            this.error = e
            this.$router.push('/')
          })
      }
    },       
    clearerror () {
      this.$store.dispatch('clearError')
    },
    checkUserRoleCreator() {
    if(this.$store.getters.usertype !== this.adminRole) {
        return true
    }
  },
  }, 
}
</script>

<style>
 /* * {
    font-family: './fonts/BalooDa2-Regular.ttf';
  } */
</style>