<template>
    <v-main>
      <v-container>
        <v-layout align-center justify-center mt-5>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <!-- <v-toolbar color="primary" flat>
                <v-toolbar-title>Recover password</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar> -->
              <v-card-text class="">
                <v-form>
                  <div class="py-8 text-center"><img style="" src="./../assets/Hubblaloggo.png" class="biglogo"/></div>
                  <v-text-field v-model="email" label="Email address" prepend-icon="mdi-account"  v-on:keyup="checkreturn"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="$router.go(-1)">Bakåt</v-btn>
                <v-spacer></v-spacer>
                <v-btn style="width: 230px !important;" :disabled="email === ''" color="primary adminlargebutton" @click="sendEmail()">Återställ lösenord</v-btn>
              </v-card-actions>
               <v-alert v-if="info" type="success">{{info}}</v-alert>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'

export default {
  data () {
    return {
      loading: false,
      email: '',
      info: '',
    }
  },
  methods: {
    checkreturn (e) {
      if (e.keyCode === 13) {
        this.sendEmail()
      }
    },
    sendEmail () {
      HTTP.post('/token/recoverpassword', 'email=' + this.email).then(() => {
        this.loading = false
        this.info = 'E-post har skickats med dina återställningsdetaljer'
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    }
  }
}

</script>

<style lang="css" scoped>

</style>
