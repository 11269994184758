<template>
  <div>
    <Appbar></Appbar>
    <v-container style="min-width:370px; max-width:80%; margin:0px auto; padding:16px">
      <div class="container-fluid">
        <div>
          <v-form v-model="valid" ref="form" @submit.prevent="save">
            <!--ExperienceName Start-->
            <div class="row">
              <div class="col-md-4 col-lg-6 col-xl-5">
                <v-card class="rounded-lg">
                  <div class="row mb-2 mr-5">
                    <v-col>
                      <v-text-field class="ml-4" v-model="editedItem.name" label="Vandringsnamn *" counter="50"
                        maxlength="50" :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </div>
                </v-card>
              </div>
              <v-col>
                <v-col>
                  <!-- <v-col class="d-flex justify-end"> -->
                  <div class="d-md-flex justify-end">
                    <v-card-actions v-if="
                      this.$store.getters.usertype == 0 ||
                      this.$store.getters.usertype == 3
                    ">
                      <div style="display: flex;">
                        <v-btn class="mr-2" @click="cancel()">
                          <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                          Avbryt
                        </v-btn>
                        <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                          <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                          Publicera
                        </v-btn>
                        <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(true)">
                          <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                          Spara
                        </v-btn>
                        <v-btn @click="deleteItem()" v-if="this.$store.getters.usertype == 3">
                          <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                          Radera
                        </v-btn>
                      </div>
                    </v-card-actions>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <div>
                        <button style="margin-right: 5px;" class="closeexp" @click="cancel()">
                          <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-close</v-icon>
                          Avbryt nu
                        </button>
                        <button style="margin-right: 5px;" class="saveexp" @click="Save(true)">
                          <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-content-save</v-icon>
                          Spara och fortsätt senare
                        </button>
                        <button class="saveexp" @click="Save(false)">
                          <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-content-save</v-icon>
                          Skicka in
                        </button>
                      </div>
                    </v-card-actions>
                  </div>
                  <!-- </v-col> -->
                </v-col>
              </v-col>
            </div>
            <!--ExperienceName Ends-->
            <!--Map start-->
            <v-card class="rounded-lg">
              <v-col style="padding-top: 0px;">
                <div id="map" class="row">
                  <link href="https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css" rel="stylesheet">
                </div>
              </v-col>
              <!--Map ends-->
              <!--Description Start-->
              <div class="row">
                <v-col>
                  <p class="ml-4 mb-0">Beskrivning av vandring *<br>(max 500 tecken)</p>
                  <v-textarea rows="2" row-height="20" counter="500" style="padding-right:15px;" class="ml-4"
                    clear-icon="mdi-close-circle" v-model="editedItem.description" maxlength="500"
                    required></v-textarea>
                </v-col>
                <!-- <v-col>
                           <div>
                              <v-radio-group style="float: right; margin-right: 40px" v-model="radioGroup">
                                 <v-radio
                                    label="Start och Slut"
                                    ></v-radio>
                                 <v-radio
                                    label="Rundvandringar"
                                    ></v-radio>
                                 <v-radio
                                    label="Valfritt"
                                    ></v-radio>
                              </v-radio-group>
                           </div>
                        </v-col> -->
              </div>
            </v-card>
            <!--Description Ends-->
            <v-card class="mt-4 rounded-lg">
              <!--Calendar starts-->
              <v-row class="justify-space-between">
                <v-col style="padding-left:10px">
                  <p class="pl-5">Datum & tid</p>
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <vc-date-picker v-model="editedItem.startTime" :model-config="modelConfig" mode="dateTime" is24hr
                      color="purple" firstDayOfWeek="2" />
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="editedItem.startTime" label="Startdatum" class="ml-4"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                        style="padding-bottom:8px; padding-right:15px"></v-text-field>
                    </template>
                  </v-menu>
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <vc-date-picker v-model="editedItem.endTime" :model-config="modelConfig" mode="dateTime" is24hr
                      color="purple" firstDayOfWeek="2" />
                    <template v-slot:activator="{ on, attrs1 }">
                      <v-text-field :value="editedItem.endTime" label="Slutdatum" class="ml-4"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs1" v-on="on"
                        style="padding-right:15px"></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
                <!--Calendar ends-->
                <!--Weekday selection starts-->
                <v-col>
                  <v-checkbox class="ml-4" v-model="isDisabledTime" label="Aktiv tid" @click="ifDisabledTime()" />
                  <a-time-picker style="padding-top:0px" v-model="editedItem.weekDaysStartTime" class="ml-4"
                    valueFormat="HH:mm" placeholder="Starttid" format="HH:mm" :disabled="!isDisabledTime" />
                  <a-time-picker v-model="editedItem.weekDaysEndTime" class="ml-4" valueFormat="HH:mm"
                    placeholder="Sluttid" format="HH:mm" :disabled="!isDisabledTime" />
                  <v-progress-circular class="progressStyle" :size="200" :width="10" v-model="loadingSave"
                    v-show="loadingSave" color="purple" indeterminate></v-progress-circular>
                </v-col>
                <!--Weekday selection buttons-->
                <v-col>
                  <v-row>
                    <v-checkbox style="padding-top:0px;" v-model="isDisabled" label="Aktiva veckodagar"
                      @click="ifDisabled(isDisabled)" />
                  </v-row>
                  <!--Weekday buttons-->
                  <v-row>
                    <div v-for="day in weekDays" :key="day.id">
                      <v-btn :value="day.id" class="mr-2 weekdaysBtn" :disabled="!isDisabled"
                        :class="buttonActiveColor(day)" @click="
                          buttonActiveBool(day);
                        buttonActiveSendValue(day);">
                        {{ day.day }}
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
            <div>
              <v-card class="rounded-lg mt-4">
                <p class="ml-4 pt-4">Lägg till upplevelser i en vandring</p>
                <div>
                  <v-dialog v-model="dialogExp" width="1000">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-1 ml-4" v-bind="attrs" v-on="on">
                        Lägg till
                      </v-btn>
                    </template>
                    <v-card>
                      <v-data-table :search="search" :headers="headers" :single-select="singleSelect"
                        :items="tableItems" :items-per-page="20" :footer-props="footerProps" class="elevation-1">
                        <template v-slot:top>
                          <v-row class="d-xl-flex align-center mx-5">
                            <v-col cols="9">
                              <v-text-field v-model="search" label="Search"></v-text-field>
                            </v-col>
                          </v-row>
                        </template>

                        <template v-slot:item="{ item }">
                          <tr style="cursor: pointer"
                            @click="item.activeOnWalk = item.activeOnWalk ? false : true, addToExperienceWalk(item)">
                            <td style="width: 40%">{{ item.name }}</td>
                            <td>{{ item.countyName + '/' + item.badgeName }}</td>
                            <td>{{ item.expTypeName }}</td>
                            <td>{{ item.creatorName }}</td>
                            <td>
                              <v-btn style="width: 130px" :color="getStatus(item.isApproved)" rounded dark>
                                {{ getStatustext(item.isApproved) }}
                              </v-btn>
                            </td>
                            <td><v-checkbox v-model="item.activeOnWalk"
                                @click="item.activeOnWalk = item.activeOnWalk ? false : true"></v-checkbox></td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialogExp = false">
                          Klar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <v-simple-table class="mb-5">
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody id="dragTable">
                    <tr v-for="item in experiences" :key="itemKey(item)" class="sortableRow sortHandle handle hoverTd">
                      <td style="display: none;" @click="deleteExperienceWalk(item)">{{ orderNumber(item) }}</td>
                      <td v-if="item.experiencetypeId == 1"><img src="../assets/icon_augmented_reality.svg" alt=""> {{
                        item.name
                      }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>
                      <td v-if="item.experiencetypeId == 2"><img src="../assets/icon_movie.svg" alt=""> {{ item.name
                        }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>
                      <td v-if="item.experiencetypeId == 3"><img src="../assets/icon_picture.svg" alt=""> {{ item.name
                        }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>
                      <td v-if="item.experiencetypeId == 5"><img src="../assets/icon_sound.svg" alt=""> {{ item.name
                        }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>
                      <td v-if="item.experiencetypeId == 4"><img src="../assets/icon_picture.svg" alt=""> {{ item.name
                        }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>
                      <td v-if="item.experiencetypeId == 6"><img src="../assets/icon_movie.svg" alt=""> {{ item.name
                        }}<a><v-icon>mdi-swap-vertical-bold</v-icon></a></td>

                      <!-- <td>{{item.expTypeName}}</td> -->
                      <!-- <td v-if="id == 0">{{ item.name }}</td> -->
                      <!-- <td v-else>{{ item.expName }}</td> -->
                      <td style="text-align: end; cursor: pointer;" @click="deleteExperienceWalk(item)">
                        <v-icon medium class="mr-2 pa-2">mdi-delete</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </div>
            <v-row class="" style="float: right; padding-right: 10px; padding-left:10px; padding-bottom:3px; flex:auto">
              <!-- <v-col class="d-flex justify-end"> -->
              <div class="d-md-flex align-start" style="padding-bottom:10px; padding-top:4px; padding-right:5px;">
                <v-card-actions v-if="
                  this.$store.getters.usertype == 0 ||
                  this.$store.getters.usertype == 3
                ">
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn class="mr-2" @click="cancel()">
                      <v-icon small class="py-2 mr-1">mdi-cancel</v-icon>
                      Avbryt
                    </v-btn>
                    <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(false)">
                      <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                      Publicera
                    </v-btn>
                    <v-btn class="mr-2" @click="checkForEmptyDates(), checkRegionBeforeSave(true)">
                      <v-icon small class="py-2 mr-1">mdi-content-save</v-icon>
                      Spara
                    </v-btn>
                    <v-btn @click="deleteItem()" v-if="this.$store.getters.usertype == 3">
                      <v-icon small class="py-2 mr-1">mdi-delete</v-icon>
                      Radera
                    </v-btn>
                  </div>
                </v-card-actions>
                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <div>
                    <button style="margin-right: 5px;" class="closeexp" @click="cancel()">
                      <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-close</v-icon>
                      Avbryt nu
                    </button>
                    <button style="margin-right: 5px;" class="saveexp" @click="Save(true)">
                      <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-content-save</v-icon>
                      Spara och fortsätt senare
                    </button>
                    <button class="saveexp" @click="Save(false)">
                      <v-icon style="color: #fff;" small class="py-2 mr-1">mdi-content-save</v-icon>
                      Skicka in
                    </button>
                  </div>
                </v-card-actions>
              </div>
              <!-- </v-col> -->
            </v-row>
            <h3>
              Skapad av:
            </h3>
            <div style="padding-top: 0px; padding-left:8px; padding-bottom:0px">
              <h2>Så här fyller du i:</h2>
              <ul>
                <div style="font-style:italic">
                  <li style="padding-bottom:5px"><strong>Upplevelsenamn:</strong> Ge din upplevelse ett namn som
                    beskriver vad
                    innehållet är men inte för långt.</li>
                  <li style="padding-bottom:5px"><strong>Beskrivning av upplevelse:</strong> Här har du möjlighet att
                    berätta mer
                    utförligt om det som du visar. Denna text kommer visas innan man startar upplevelsen.</li>
                  <li style="padding-bottom:5px"><strong>Start & Slutdatum:</strong>
                    Det kan vara bra om du vill att något ska vara tillgängligt först från en speciell dag eller du
                    kanske bara vill
                    att en sak ska finnas under en viss årstid
                  </li>
                  <li style="padding-bottom:5px"><strong>Kommun:</strong> Välj i vilken kommun som upplevelsen finns
                  </li>
                  <li style="padding-bottom:5px"><strong>Typ av upplevelse:</strong> Välj någon du tycker passar bäst.
                    Du kan bara
                    välja en.</li>
                  <li style="padding-bottom:5px"><strong>Badges:</strong> Välj någon du tycker passar bäst. Du kan bara
                    välja en.
                  </li>
                  <li style="padding-bottom:5px"><strong>Aktiv tid:</strong> Välj en tid när du önskar att upplevelsen
                    ska vara
                    aktiv under dagen </li>
                  <li style="padding-bottom:5px"><strong>Aktiva veckodagar:</strong> Välj vilka dagar som önskas när
                    upplevelsen ska
                    vara aktiv. (Lämna blank eller inaktiv om upplevelsen ska vara aktiv varje dag)</li>
                  <li style="padding-bottom:5px"><strong>Karta:</strong> Din upplevelse är alltid kopplad till en
                    specifik plats.
                    Vi använder samma koordinatsystem som Google Maps.
                  </li>
                </div>
              </ul>
            </div>
            <h2 style="padding-left:10px">Om innehållet:</h2>
            <div style="padding-top: 0px; padding-left:15px; font-style:italic">
              <li style="padding-bottom:5px"><strong>Film:</strong> Försök att hålla filmen relativt kort och koncis i
                förhållande
                till sin placering och innehåll. Filmen får vara som längst 5 minuter lång. Stående format är att
                föredra men det
                går bra med liggande oxå. Spela in i minst 720p men gärna 1080p. Filmen ska komprimeras med h.264 codec
                i
                mp4-format.</li>
              <li style="padding-bottom:5px"><strong>Bild:</strong> Tänk på att bilden ska vara mobilanpassad. </li>
              <li style="padding-bottom:5px"><strong>Ljud:</strong> Storlek, Försök att hålla ljudklippet relativt kort
                och koncist
                i förhållande till sin placering och innehåll. Ljudklippet får vara som längst 5 minuter långt. </li>
              <li style="padding-bottom:5px"><strong>AR: </strong> Mejla en beskrivning av önskad AR-upplevelse till
                fredrik.jakobsson@mindflower.se.</li>
            </div>
            <h2 style="padding-left:10px">Tänk på: </h2>
            <div style="padding-top: 0px; padding-left:10px; font-style:italic">
              <ul class="my-0">
                <li>Dela inte innehåll med personuppgifter, till exempel bilder och filmer på personer eller namn, utan
                  godkännande.
                </li>
                <li>Upphovsrätt. Kontrollera om du får dela ditt innehåll, t ex gamla bilder.</li>
              </ul>
              <br>
            </div>
          </v-form>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Sortable from 'sortablejs';
import { HTTP } from "@/plugins/backendAPI";
import "vue-date-pick/dist/vueDatePick.css";
import VCalendar from "v-calendar";
import Vue from "vue";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Appbar from "../components/appbar.vue";
import router from "../router";
Vue.use(VCalendar, {
  componentPrefix: "vc"
});
export default {
  props: ["id"],
  data() {
    return {
      dialog: true,
      radioGroup: 0,
      singleSelect: false,
      selected: false,
      testCheckbox: null,
      dialogExp: false,
      search: "",
      footerProps: { "items-per-page-options": [50, 100] },
      headers: [
        { text: "Namn", value: "name" },
        { text: "Kommun", value: "countyName" },
        { text: "Badge typ", value: "badgeName" },
        { text: "Upplevelse typ", value: "expTypeName" },
        { text: "Status", value: "isApproved" },
        // { text: "Redigera", value: "action", sortable: false, width: 130 },
        { text: "Lägg till / Ta bort", value: "activeOnWalk", sortable: false, width: 130 }
      ],
      creatorHeader: [
        { text: "Namn", value: "name" },
        { text: "Kommun", value: "countyName" },
        { text: "Upplevelse typ", value: "expTypeName" },
        { text: "Redigera", value: "action", sortable: false, width: 130 }
      ],
      allTypes: [],
      editedItem: {},
      weekDays: [
        { id: 2, day: "Mån", isActive: false },
        { id: 3, day: "Tis", isActive: false },
        { id: 4, day: "Ons", isActive: false },
        { id: 5, day: "Tor", isActive: false },
        { id: 6, day: "Fre", isActive: false },
        { id: 7, day: "Lör", isActive: false },
        { id: 1, day: "Sön", isActive: false }
      ],
      titleMain: "Drag & Drop of Vuetify's v-simple-table using SortableJS",
      titleSub: '[Vuetify 2.0.3 + SortableJS 1.10.1]',
      memo: 'UPDATE：2020-02-06 ',
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      experiences: [],
      activeOnWalk: null,
      isDisabled: false,
      isDisabledTime: false,
      step: 0,
      loading: null,
      loadingSave: null,
      markers: [],
      tableItems: [],
      orderId: [],
      testDaysSaved: [],
      uniqueData: null,
      checkbox1: false,
      showAlert: false,
      valid: null,
      dialoglists: {},
      testExp: {},
      walksCoordinates: [],
      AdminApproved: "",
      detailsdialog: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm"
      },
      modelConfigTime: {
        type: "date",
        mask: "HH:mm"
      },
      location: [],
      marker: {},
      access_token:
        "pk.eyJ1IjoibWluZGZsb3dlciIsImEiOiJjbDBtMjBiNWsxMDdvM2tuc3FhanZ4cTh5In0.zld6Cvhb_W59V5XTLckfYw",
      startingPoint: [16.547054645517534, 59.610491130108215],
      map: null,
      coordinates: [],
      geocoder: {},
      rules: {
        required: value => !!value || "",
        min: v => v.length < 500 || "Max 500 bokstäver"
      }
    };
  },

  created() {
    this.getWalks(this.id);
    this.getitemsid(this.id);
    this.getitems(this.$store.getters.id);
    // this.getitemsforExp(this.$store.getters.id);
    // this.checkValidRegion();
  },
  mounted() {
    this.createMap();

    new Sortable(
      document.querySelector('#dragTable'),
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder
      }
    )
  },
  computed: {
    pprint: {
      get() { return JSON.stringify(this.experiences, undefined, 4) }
    },
    sortableVersion: {
      get() { return Sortable.version }
    }
  },
  watch: {
    experiences: function () {
      this.getValues();
      this.markers.forEach(marker => {
        let isMarkerExist = this.experiences.find(experience => experience.experienceId === marker.id);
        if (!isMarkerExist) {
          marker.remove();
        }
      });
    }
  },

  methods: {
    itemKey(item) {
      if (!this.itemKeys.has(item)) this.itemKeys.set(item, ++this.currentItemKey)
      return this.itemKeys.get(item)
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.experiences.splice(oldIndex, 1)[0]
      this.experiences.splice(newIndex, 0, movedItem)
    },
    orderNumber(item) {
      return this.experiences.indexOf(item) + 1;
    },
    onClick() {
      this.dialog = true
    },

    pushProp(showAlert) {
      router.push({ name: 'Experiences', query: { tab: 'tab-2' }, params: { showAlert: showAlert }, props: { showAlert: showAlert } })
    },
    addToExperienceWalk(item) {
      if (item.activeOnWalk == true) {
        this.experiences.push(item)
      } else {
        this.dialog = false;
        this.$root.$confirm
          .open("Radera upplevelse från Vandring.", "Är du säker?", { color: "red" })
          .then(response => {
            if (response) {
              item.activeOnWalk = false;
              const finder = this.experiences.find(x => x.experienceId == item.id);
              if (finder != null) {
                finder.activeOnWalk = false;
                this.experiences.splice(this.experiences.indexOf(finder), 1)
                HTTP.post("/experience/deleteexperiences", { id: finder.id })
              }
              else {
                this.experiences.splice(this.experiences.indexOf(item), 1);
                // HTTP.post("/experience/deleteexperiences", { experienceId: item.id })
              }
            }
            else {
              item.activeOnWalk = true;
            }
          });
      }
      this.getValues();
    },


    async deleteExperienceWalk(item) {
      if (item.id > 0) {
        this.dialog = false;
        this.$root.$confirm
          .open("Radera upplevelse från Vandring.", "Är du säker?", { color: "red" })
          .then(response => {
            if (response) {
              item.activeOnWalk = false;
              const dbfinder = this.tableItems.find(x => x.id == item.experienceId);
              if (dbfinder != null) {
                dbfinder.activeOnWalk = false;
              }
              this.experiences.splice(this.experiences.indexOf(item), 1);
              HTTP.post("/experience/deleteexperiences", { id: item.id });
            }
          });
      }
    },

    checkRegionBeforeSave(justSave) {
      this.Save(justSave)
    },
    getitemsid(id) {
      if (this.id > 0) {
        HTTP.get("/experience/getwalk/id/" + id).then(response => {
          this.editedItem = response.data;
          // this.coordinates = this.editedItem.coordinate.split(",");
          this.getValues();
        })
      }
      HTTP.get("/experience/gettypes").then(response => {
        this.dialoglists = response.data;
        // this.mapClick();
        // this.checkValidRegion();
      });
    },
    async checkValidRegion() {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.coordinates[0]},${this.coordinates[1]}.json?types=place&access_token=${this.access_token}`
      );
      this.loading = false;
      this.location = response.data.features[0].place_name.split(",");
    },
    mapSettings() {
      this.geocoder = new MapboxGeocoder({
        accessToken: this.access_token,
        enableHighAccuracy: true,
        mapboxgl: mapboxgl,
        marker: false,
        language: 'sv-SV',
        reverseGeocode: true
      });
    },
    async createMap() {
      mapboxgl.accessToken = this.access_token;
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: this.startingPoint,
        zoom: 8
      });
      let geocoder = new MapboxGeocoder({
        accessToken: this.access_token,
        enableHighAccuracy: true,
        mapboxgl: mapboxgl,
        marker: false,
        reverseGeocode: true,
        language: 'sv-SV',
      });
      this.map.addControl(geocoder);
    },

    ifDisabledTime() {
      if (this.isDisabledTime == false) {
        this.editedItem.weekDaysStartTime = null;
        this.editedItem.weekDaysEndTime = null;
      }
    },
    ifDisabled() {
      if (this.isDisabled == false) {
        this.resetValues();
        this.buttonActiveColor(this.weekDays);
      }
    },
    remove(day) {
      this.testDaysSaved.splice(this.testDaysSaved.indexOf(day.id), 1);
    },
    buttonActiveColor(day) {
      return day.isActive ? "primary" : "";
    },
    buttonActiveBool(day) {
      return (day.isActive = day.isActive ? false : true);
    },
    buttonActiveSendValue(day) {
      return day.isActive ? this.testDaysSaved.push(day.id) : this.remove(day);
    },
    checkForEmptyDates() {
      if (this.testDaysSaved == "" || this.testDaysSaved == []) {
        this.testDaysSaved = null;
        this.editedItem.weekDaysActive = null;
        // if(this.editedItem.weekDaysStartTime && this.editedItem.weekDaysEndTime == null || [] || "")
        //   this.editedItem.weekDaysStartTime = null;
        //   this.editedItem.weekDaysEndTime = null;
      } else {
        this.removeDuplicates();
      }
    },

    createMarker(coordinate) {
      this.marker = new mapboxgl.Marker().setLngLat(coordinate).addTo(this.map);
    },

    getValues() {
      this.testDaysSaved = [];
      if (this.markers != null) {
        this.markers.forEach(element => {
          element.marker.remove();
        });
      }
      this.markers = [];
      this.experiences.forEach((experience, index) => {
        var coordinate = experience.coordinate.split(',');
        var div = document.createElement('div');
        div.innerHTML = `<p style="color: #fff; padding-top: 17px; font-size: 24px;">${index + 1}</p>`;
        div.style.backgroundColor = '#7d4c9e';
        div.style.borderRadius = '50%';
        div.style.width = '40px';
        div.style.height = '40px';
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.top = '-10px'
        div.style.justifyContent = 'center';
        div.style.color = '#333';
        div.style.fontSize = '16px';

        var spets = document.createElement('div');
        spets.style.borderTop = '12px solid #7d4c9e';
        spets.style.borderLeft = '4px solid transparent';
        spets.style.borderRight = '4px solid transparent';
        spets.style.position = 'absolute';
        spets.style.bottom = '-10px';
        spets.style.left = '50%';
        spets.style.transform = 'translateX(-50%)';
        div.appendChild(spets);

        // skapa pop up med div-elementet som innehåll
        // var popup = new mapboxgl.Popup().setDOMContent(div);

        // skapa marker med div-elementet som ikon och lägg till pop upen på kartan

        let marker = new mapboxgl.Marker(div, {
          anchor: 'bottom',
        })
          .setLngLat(coordinate)
          // .setPopup(popup)
          .addTo(this.map);

        this.markers.push({ marker, id: experience.experienceId });
      });


      // if (this.experiences != []) {
      //   this.startingPoint = this.coordinates
      // }
      // for (let i = 0; i < this.weekDays.length; i++) {
      //   var index = this.weekDays[i];
      //   if (this.editedItem.weekDaysActive == null) {
      //     index.isActive = Boolean(false);
      //     this.isDisabled = Boolean(false);
      //   } else if (this.editedItem.weekDaysActive.includes(index.id)) {
      //     index.isActive = Boolean(true);
      //     this.isDisabled = Boolean(true);
      //     this.testDaysSaved.push(index.id);
      //   } else {
      //     index.isActive = Boolean(false);
      //   }
      // }

      // if (
      //   this.editedItem.weekDaysStartTime &&
      //   this.editedItem.weekDaysEndTime != null
      // ) {
      //   this.isDisabledTime = Boolean(true);
      // }
      // this.editedItem.weekDaysActive = null;
      // this.editedItem.weekDaysActive = new Set();
      // this.testDaysSaved.forEach(day => {
      //   this.editedItem.weekDaysActive.add(day);
      // });

    },
    removeDuplicates() {
      this.editedItem.weekDaysActive = [...new Set(this.testDaysSaved)].join();
    },
    resetValues() {
      for (let i = 0; i < this.weekDays.length; i++) {
        var index = this.weekDays[i];
        index.isActive = Boolean(false);
      }
      // this.marker.remove();
      this.testDaysSaved = [];
      this.editedItem.weekDaysStartTime == null;
      this.editedItem.weekDaysEndTime == null;
      // this.editedItem.coordinate == null;
      // this.coordinates = [];
    },
    getitemsforExp(userId) {
      HTTP.get("/experience/get/exponwalk/" + userId).then(response => {
        this.tableItems = response.data;
        const tableKeys = Object.keys(this.tableItems);
        const expKeys = Object.keys(this.experiences);
        for (var i = 0; i < tableKeys; i++) {
          for (var j = 0; j < expKeys; j++) {
            if (this.tableItems[i].id === this.experiences[j].experienceId)
              this.tableItems[i].activeOnWalk = true;
          }
        }
      });
    },

    async getitems(userId) {
      try {
        const response = await HTTP.get("/experience/get/exponwalk/" + userId);
        this.tableItems = response.data;
        const expKeys = Object.keys(this.experiences);
        const tableKeys = Object.keys(this.tableItems);
        for (let key_Index = 0; key_Index < expKeys.length; key_Index++) {
          for (let tbl_Index = 0; tbl_Index < tableKeys.length; tbl_Index++) {
            const tbl = this.tableItems[tbl_Index];
            const exp = this.experiences[key_Index];
            if (tbl.id === exp.experienceId) {
              tbl.activeOnWalk = true;
            }

          }
        }
        const experiencesMap = this.experiences.reduce((map, experience) => {
          map[experience.experienceId] = true;
          return map;
        }, {});
        this.tableItems.forEach(item => {
          item.activeOnWalk = experiencesMap[item.id] || false;
        });
      } catch (error) {
        // handle error
      }
    },
    newitem() {
      this.editItem();
      this.mapSettings();
    },
    getWalks(id) {
      if (this.id > 0) {
        HTTP.get("/experience/getwalks/id/" + id).then(response => {
          this.experiences = response.data;
        })
      }

    },
    editItem() {
      this.editedItem = Object.assign({}, this.testExp);
      HTTP.get("/experience/gettypes").then(response => {
        this.dialoglists = response.data;
      });
    },
    deleteItem() {
      const item = this.editedItem;
      this.dialog = false;
      var self = this;
      this.$root.$confirm
        .open("Radera vandring", "Är du säker?", { color: "red" })
        .then(response => {
          if (response) {
            HTTP.post("/experience/deletewalk", { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1);
              this.getitems(this.$store.getters.id);
              router.push({ name: 'Experiences', query: { tab: 'tab-2' }, props: this.showAlert })
            });
          }
        });
    },
    getStatustext(item) {
      switch (item) {
        case false:
          return "Ej godkänd";
        case null:
          return "Pågående";
        case true:
          return "Publicerad";
        case 1:
          return "Sparad";
      }
    },
    getStatus(item) {
      switch (item) {
        case false:
          return "red";
        case null:
          return "orange";
        case true:
          return "green";
        case 1:
          return "purple";
      }
    },
    saveWalk(publish) {
      this.loadingSave = true;
      this.experiences.forEach(item => {
        this.orderNumber(item);
      });
      this.editedItem.expWalkType = this.radioGroup
      this.editedItem.experiences = this.experiences
      this.editedItem.experiences.orderId = this.orderNumber;

      this.editedItem.isApproved = publish ? true : null;
      HTTP.post("/experience/savewalk", this.editedItem)
        .then(() => {

          this.getitems(this.$store.getters.id);
          this.loadingSave = false;
          // this.tableItems.push(this.editedItem);
          this.dialog = false;
          this.$refs.form.resetValidation();
          setTimeout(() => {
            this.showAlert = false;
          }, 7000);
          // window.location.replace("/experiences")
          router.push({ name: 'Experiences', query: { tab: 'tab-2' }, props: this.showAlert })
            .catch(e => {
              this.loadingSave = false;
              this.$store.dispatch(
                "showError",
                "Fel vid sparning: " + e.response.data
              );
            });
          window.location.reload();
        })
    },
    Save(justSave) {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$root.$okconfirm.open(
          "",
          "Vänligen fyll i de rödmarkerade fälten i formuläret",
          { color: "red" }
        );
        return;
      }

      if (this.editedItem.isApproved && justSave) {
        this.$root.$confirm.open(
          "Uppdatering av upplevelsen kommer att avpublicera din upplevelse i väntan på nytt godkännande.",
          "Är du säker?",
          { color: "red" }
        ).then(response => {
          if (response) {
            this.saveWalk(!justSave);
          }
        });
      } else {
        this.saveWalk(!justSave);
      }

    },
    cancel() {
      this.dialog = false;
      this.$refs.form.resetValidation();
      router.push({ name: 'Experiences', query: { tab: 'tab-2' }, props: this.showAlert = true })
      // window.location.replace("/experiences")
    }
  },
  components: { Appbar }
};
</script>

<style scoped>
input {
  display: none;
}

.hoverTd a {
  visibility: hidden;
}

.hoverTd:active a {
  visibility: visible
}

;

.buttoncreate {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

/*
.marker {
  background-color: #eee;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 16px;
} */

/* .markerWalks {
        background-color:'#eee';
      border-radius: '50%';
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    color: #333;
     font-size: 16px;
} */

.buttoncreate:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}

.closeexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #d2d2d2;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #a3a3a4;
}

.closeexp:active {
  box-shadow: 0 5px #d2d2d2;
  transform: translateY(4px);
}

.saveexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #6abf62;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #4ca645;
}

.saveexp:active {
  box-shadow: 0 5px #6abf62;
  transform: translateY(4px);
}

.uploadbuttoncreator {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: orange;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px rgb(214, 141, 4);
}

.progressStyle {
  position: absolute;
  z-index: 1;
  top: 21vh;
  left: 25vh;
}

.saveexp:active {
  box-shadow: 0 5px orange;
  transform: translateY(4px);
}

/* Info icon */

.relativeDiv {
  position: relative;
}

.help-tip {
  transform: translate(-50%, -50%);
  margin: 12px 15px;
  text-align: center;
  border: 2px solid #444;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 21px;
  cursor: default;
  z-index: 1;
}

.help-tip:before {
  content: "i";
  font-family: sans-serif;
  font-weight: normal;
  color: rgb(17, 17, 17);
}

.help-tip:hover p {
  display: block;
  transform-origin: -100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}

.help-tip p {
  display: none;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  background-color: #ffffff;
  padding: 12px 16px;
  width: 300px;
  height: auto;
  position: absolute;
  top: -370px;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 3px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393d;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
}

/* .label-color /deep/ label {
  color: red;
} */

.help-tip p a {
  color: #067df7;
  text-decoration: none;
}

.help-tip p a:hover {
  text-decoration: underline;
}

/* Prevents the tooltip from being hidden */
.help-tip p:after {
  width: 10px;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.approvedAndCreatedBy {
  text-align: right;
  padding-right: 10px;
  margin-bottom: 0px;
}

.weekdaysBtn {
  width: 50px;
  height: 25px;
  font-size: 12px;
}

#marker-text {
  color: #fff;
  font-size: 30px;
}

.main {
  padding: 45px 50px;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-holder {
  width: 65%;
}

#map {
  height: 40vh;
}

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}
</style>
