<template>
    <v-main >
      <v-container style="max-width: 600px; margin-top: 100px;">
        <div class="py-8 text-center"><img src="./../assets/Hubblaloggo.png" style="width: 350px;"/></div>
        <v-layout class="align-center justify-center mt-5">
               <v-card class="px-4">
                  <v-card-text>
                     <v-form>
                       <v-row>
                        <v-col cols="12">
                        <v-text-field
                           v-model="userinfo.email"
                           label="E-mail"
                           name="login"
                           prepend-icon="mdi-account"
                           type="text"
                           v-on:keyup="checkreturn"
                           ></v-text-field>
                        </v-col> 
                        <v-col cols="12">
                        <v-text-field
                           v-model="userinfo.password"
                           id="password"
                           label="Lösenord"
                           name="password"
                           prepend-icon="mdi-lock"
                           type="password"
                           v-on:keyup="checkreturn"
                           ></v-text-field>
                           </v-col> 
                           <v-col class="d-flex" cols="12" sm="6" xsm="12">
                           </v-col>
                           <v-spacer></v-spacer>
                       <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="deleteuseracc" @click="login()">Radera konto</v-btn>
              </v-card-actions>
                       </v-row>
                     </v-form>
                  </v-card-text>
               </v-card>
        </v-layout>
      </v-container>
    </v-main>
</template>

<script>
import axios from 'axios'
import { HTTP } from '@/plugins/backendAPI'

export default {
  mounted() {
    this.userinfo.email = this.$route.params.email
  },
  data () {
    return {
      userinfo: {
        email: '',
        password: '',
        usertype: ''
      },
      info: '',
      editedItem: {},
      tableItems: [],  
      dialog: true,
      tab: 0,
      tabs: [
        {name:"Logga in", icon:"mdi-account"},
        {name:"Skapa konto", icon:"mdi-account-outline"}
    ],
    loading: false,
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 4 || 'Min 4 characters'
      },
      showpassword: false
    }
  },
  methods: {
    checkreturn (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    },
    login () {
      this.loading = true
      var self = this
      var params = 'username=' + this.userinfo.email + '&password=' + encodeURIComponent(this.userinfo.password)
      delete HTTP.defaults.headers.common['Authorization']
      // eslint-disable-next-line
      axios({ method: 'post', url: process.env.VUE_APP_APIURL + '/token/login/admin ', data: params, headers: { 'accept': 'application/json', 'content-type': 'application/x-www-form-urlencoded' } })
        .then(response => {
          self.loginSuccessful(response)
          
        })
        .catch(ex => {
          self.loginFailed(ex)
        })
    },
    loginSuccessful (req) {
      if (!req.data.bearer || req.data.usertype < 2) {
        this.loginFailed()
        return
      }
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + req.data.bearer
      }
      HTTP.post('/account/getkund', { email: this.userinfo.email })
        .then(() => {
        this.$store.dispatch('saveAuthorization', {id: req.data.id, username: this.userinfo.email, usertype: req.data.usertype, isauthorized: true, bearertoken: req.data.bearer})
        var self=this
        this.$root.$confirm.open('Varning!', 'Ditt konto med all info kommer att raderas. Är du helt säker?', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/users/delete',{id: req.data.id}).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(req), 1)
            this.$router.push({ path: '/userdeleted' })
          })
        }
      })
        })
        .catch(e => {
          this.$store.dispatch('showError', e.message)
          this.loading = false
        })
    },
    loginFailed () {
      this.loading = false
      this.$store.dispatch('showError', 'Kontrollera uppgifterna')
      this.$store.dispatch('deleteAuthorization')
    },  
  }
}

</script>

<style lang="css" scoped>
.deleteuseracc {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: black;
  background: #DB6352;
  background-color: #DB6352!important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #D44121;
}

.deleteuseracc:active {
  box-shadow: 0 5px #DB6352;
  transform: translateY(4px);
}
</style>