import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: localStorage.id,
    username: localStorage.username,
    isauthorized: localStorage.isauthorized,
    bearertoken: localStorage.bearertoken,
    usertype: localStorage.usertype,
    errordialog: false,
    errormessage: null,
    workingdialog: false    
  },
  getters: {
    id: state => {
      return state.id
    },
    username: state => {
      return state.username
    },
    isauthorized: state => {
      return state.isauthorized
    },
    bearertoken: state => {
      return state.bearertoken
    },
    usertype: state => {
      return state.usertype
    },
    errordialog: state => {
      return state.errordialog
    },
    errormessage: state => {
      return state.errormessage
    },
    workingdialog: state => {
      return state.workingdialog
    }
  },
  mutations: {
    initStorage (state) {
      if (localStorage.id === 'undefined') {
        localStorage.id = state.id === undefined ? '' : state.id
      }
      if (localStorage.username === 'undefined') {
        localStorage.username = state.username === undefined ? '' : state.username
      }
      if (localStorage.isauthorized === 'undefined') {
        localStorage.isauthorized = state.isauthorized === undefined ? '' : state.isauthorized
      }
      if (localStorage.bearertoken === 'undefined') {
        localStorage.bearertoken = state.bearertoken === undefined ? '' : state.bearertoken
      }
      if (localStorage.usertype === 'undefined') {
        localStorage.usertype = state.usertype === undefined ? null : state.usertype
      }
    },
    saveAuthorization (state, authinfo) {
      state.id = authinfo.id
      state.username = authinfo.username
      state.isauthorized = authinfo.isauthorized
      state.bearertoken = authinfo.bearertoken
      state.usertype = authinfo.usertype
  
      localStorage.id = authinfo.id
      localStorage.username = authinfo.username
      localStorage.isauthorized = authinfo.isauthorized
      localStorage.bearertoken = authinfo.bearertoken
      localStorage.usertype = authinfo.usertype
    },
    deleteAuthorization (state) {
      state.username = null
      state.isauthorized = null
      state.bearertoken = null
  
      delete localStorage.username
      delete localStorage.isauthorized
      delete localStorage.bearertoken
    },
    showError (state, errormessage) {
      state.errordialog = true
      state.errormessage = errormessage
    },
    clearError (state) {
      state.errordialog = false
      state.errormessage = null
    },
    showWorkingStatus (state) {
      state.workingdialog = true
    },
    clearWorkingStatus (state) {
      state.workingdialog = false
    }    
  },
  actions: {
    initStorage ({ commit }) {
      commit('initStorage', null)
    },
    saveAuthorization ({ commit }, authinfo) {
      commit('saveAuthorization', authinfo)
    },
    deleteAuthorization ({ commit }) {
      commit('deleteAuthorization', null)
    },
    showError ({ commit }, errormessage) {
      commit('showError', errormessage)
    },
    clearError ({ commit }) {
      commit('clearError', null)
    },
    showWorkingStatus ({ commit }) {
      commit('showWorkingStatus', null)
    },
    clearWorkingStatus ({ commit }) {
      commit('clearWorkingStatus', null)
    }    
  },
  modules: {
  }
})
