import Vue from 'vue'
import VueRouter from 'vue-router'
import Statistics from '@/views/Statistics.vue'
import Experiences from '@/views/Experiences.vue'
import Users from '@/views/Users.vue'
import Administrators from '@/views/Administrators.vue'
import IntegrityPolicy from '@/views/IntegrityPolicy'
import IntegrityPolicy2 from '@/views/IntegrityPolicy2'
import Home from '@/views/Home.vue'
import Creators from '@/views/Creators.vue'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import Register from '@/views/Register'
import RecoverPassword from '@/views/RecoverPassword'
import RecoverUserPassword from '@/views/users/RecoverUserPassword'
import DeleteUserAccount from '@/views/DeleteUserAccount'
import UserDeleted from '@/views/UserDeleted'
import ChangePassword from '@/views/ChangePassword'
import LoginPlayer from '@/views/LoginPlayer'
import RegisterPlayerToCreator from '@/views/RegisterPlayerToCreator'
import Map from '@/views/Map'
import ModifyExperience from '@/views/ModifyExperience.vue'
import HikingExperiences from '@/views/HikingExperiences.vue'


// import allExperience from '@/views/allExperience'



// import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },  
  {
    path: '/experiences',
    name: 'Experiences',
    props: true,
    component: Experiences,
  },  
  // {
  //   path: '/upplevelser',
  //   name: 'allExperience',
  //   component: allExperience
  // },  
  {
    path: '/users',
    name: 'Users',
    component: Users
  }, 
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/loginplayer',
    name: 'LoginPlayer',
    component: LoginPlayer
  }, 
  {
    path: '/registercreator', 
    name: 'RegisterPlayerToCreator',
    component: RegisterPlayerToCreator
  },
  {
    path: '/administrators',
    name: 'Administrators',
    component: Administrators
  }, 
  {
    path: '/creators',
    name: 'Creators',
    component: Creators
  }, 
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/integritypolicy',
    name: 'IntegrityPolicy',
    component: IntegrityPolicy
  },
  {
    path: '/integritypolicyc',
    name: 'IntegrityPolicy2',
    component: IntegrityPolicy2
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/recoverpassword',
    name: 'RecoverPassword',
    component: RecoverPassword
  },  
  {
    path: '/recoveruserpassword',
    name: 'RecoverUserPassword',
    component: RecoverUserPassword
  },  
  {
    path: '/deleteuseraccount/:email?',
    name: 'DeleteUserAccount',
    component: DeleteUserAccount
  },  
  {
    path: '/userdeleted',
    name: 'UserDeleted',
    component: UserDeleted
  },  
  {
    path: '/changepassword/:guid',
    name: 'ChangePassword',
    props: true,
    component: ChangePassword
  },
  {
    path: '/Map',
    name: 'Map',
    component: Map
  },   
  {
    path: '/modifyExperience/',
    name: 'ModifyExperience',
    props: true,
    component: ModifyExperience
  }, 
  {
    path: '/HikingExperiences/',
    name: 'HikingExperiences',
    props: true,
    component: HikingExperiences
  }, 
]


const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   var self = store.getters
//   if (to.name === 'Login' || to.name === 'ChangePassword' || to.name === "DeleteUserAccount" || to.name === "RecoverPassword" || to.name === "IntegrityPolicy" || to.name === "LoginPlayer" || to.name === "RegisterPlayerToCreator" || to.name === "allExperience") {
//     return next()
//   }
//   else if (self.usertype === 1 && to.name !== 'Experiences') {
//     return next({ path: '/experiences'})
//   }
//   else if ((self.isauthorized === 'undefined' || self.isauthorized === false)) {
//     return next({ path: '/'})
//   }
//   else {
//       next()
//   }
  
// })

export default router
