
<template>
  <div>
    <appbar></appbar>
    <v-alert
    style="text-align: center;"
    v-model="showAlert"
    transition="slide-y-transition"
      type="success"
    >
    <strong>Tack för ditt bidrag!</strong> Vi återkommer till dig via mejl efter vi har granskat upplevelsen.
    </v-alert>
    <v-row justify="center" class=" mt-5">
      <v-col md="8" sm="12">
            <v-tabs
      v-model="tab"
      background-color="#fff"
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Upplevelser
      </v-tab>

      <v-tab href="#tab-2">
        Vandringar
      </v-tab>
    </v-tabs>
        <v-tabs-items :value="tab">
      <v-tab-item
        value="tab-1"
      >
        <v-card flat>
          <v-card-text>
     <v-data-table
          :search="search"
          :headers="headers"
          :items="tableItems"
          :items-per-page="20"
          :footer-props="footerProps"
          :custom-filter="customSearch"
          class="elevation-1"
          v-if="this.$store.getters.usertype == 0 || this.$store.getters.usertype == 3"
        >
          <template v-slot:top>
            <v-row class="d-xl-flex align-center mx-5">
              <v-col cols="9">
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn tile outlined @click="newitem(), resetValues()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon>
                  Ny
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="{ item }">
            <tr @click="redirectPageWithValue(item)" style="cursor: pointer">
              <td style="width: 40%">{{ item.name}}</td>
              <!-- <td>{{item.countyName}}</td>
              <td>{{item.badgeName}}</td> -->
              <!-- Funktion för att lägga till icon istället för (V) i Kommun/Vandring-->

              <!-- <td v-if="item.activeOnWalk">{{ item.countyName }}  <v-icon>mdi-walk</v-icon></td>
              <td v-else>{{ item.countyName }}</td> -->

              <!-- Funktion för att lägga till icon istället för (V) i Kommun/Vandring -->
              <td v-if="item.activeOnWalk">{{ item.countyName + '(V)'}}</td>
              <td v-else>{{ item.countyName}}</td>
              <td>{{ item.expTypeName + '/' + item.badgeName }}</td>
              <td>{{ item.creatorName }}</td>
              <td style="padding-left:0px;">
                <v-menu :nudge-width="40" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="width: 130px"
                      :color="getStatus(item.isApproved)"
                      rounded
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="showstatusdialog(item)"
                    >
                      {{ getStatustext(item.isApproved) }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-subtitle class="text-center pt-1">
                      <strong>Ändra status</strong></v-card-subtitle
                    >
                    <v-card-text>
                      <div class="text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="deniedExperience(item, 0)"
                          :color="getStatus(false)"
                          dark
                          >Neka</v-btn
                        >
                      </div>
                      <div class="my-3 text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="setStatus(item, 1)"
                          :color="getStatus(null)"
                          dark
                          >Pågående</v-btn
                        >
                      </div>
                       <!--  <div class="my-3 text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="setStatus(item, 3)"
                          :color="getStatus(1)"
                          dark
                          >Sparad</v-btn
                        >
                      </div> -->
                      <div class="text-center">
                        
                        <v-btn
                          rounded
                          width="120px"
                          @click="setStatus(item, 2)"
                          :color="getStatus(true)"
                          dark
                          >Godkänd</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </td>
              <td>
                <v-icon medium class="mr-2 pa-2" @click="editItem(item), getValues()"
                  >mdi-pencil</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table
          :search="search"
          :headers="creatorHeader"
          :items="tableItems"
          :items-per-page="20"
          :footer-props="footerProps"
          class="elevation-1"
          v-else
        >
          <template v-slot:top>
            <v-row class="d-xl-flex align-start mx-5">
              <v-col cols="12">
                <h2>Upplevelser</h2>
              </v-col>
            </v-row>
            <v-row class="d-xl-flex align-center mx-5">
              <v-col cols="9">
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-col cols="3">
                <button class="buttoncreate" @click="newitem()">
                  Ny upplevelse
                </button>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="{ item }">
            <tr @click="redirectPageWithValue(item)
                " style="cursor: pointer">
              <td style="width: 40%">{{ item.name  + '/' + item.badgeName }}</td>
              <td>{{ item.countyName }}</td>
              <td>{{ item.badgeName }}</td>
              <td>{{ item.expTypeName }}</td>
              <td>
                <v-menu :nudge-width="40" offset-y>
                  <template v-slot:activator="{}">
                    <v-btn
                      style="width: 130px"
                      :color="getStatus(item.isApproved)"
                      dark
                    >
                      <strong> {{ getStatustext(item.isApproved) }} </strong>
                    </v-btn>
                  </template>
                </v-menu>
              </td>
              <td>
                <v-icon medium class="mr-2 pa-2" @click="redirectPageWithValue(item)"
                  >mdi-pencil</v-icon
                >
              </td>
            </tr>
          </template>
          <template v-slot:item.isApproved="{ item }">
            <v-text v-model="item.isApproved"></v-text>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="mr-2 pa-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>

          </v-card-text>
        </v-card>
      </v-tab-item>



    <!-- VANDRINGAR -->
    <v-tab-item
        value="tab-2"
      >
        <v-card flat>
          <v-card-text>
          <v-data-table
          :search="search"
          :headers="headerWalk"
          :items="tableWalkExpItems"
          :items-per-page="20"
          :footer-props="footerProps"
          class="elevation-1"
          v-if="this.$store.getters.usertype == 0 || this.$store.getters.usertype == 3"
        >
          <template v-slot:top>
            <v-row class="d-xl-flex align-center mx-5">
              <v-col cols="9">
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn tile outlined @click="newWalk(), resetValues()">
                  <v-icon small class="py-2 mr-2">mdi-flip-to-front</v-icon>
                  Ny
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="{ item }">
            <tr @click="redirectPageWithValueToHikingExperiences(item)" style="cursor: pointer">
              <td style="width: 40%">{{ item.name}}</td>
              <td>Vandring</td>
              <td style="padding-left:0px;">
                <v-menu :nudge-width="40" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="width: 130px"
                      :color="getStatus(item.isApproved)"
                      rounded
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="showstatusdialog(item)"
                    >
                      {{ getStatustext(item.isApproved) }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-subtitle class="text-center pt-1">
                      <strong>Ändra status</strong></v-card-subtitle
                    >
                    <v-card-text>
                      <div class="text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="deniedExperienceWalk(item, 0)"
                          :color="getStatus(false)"
                          dark
                          >Neka</v-btn
                        >
                      </div>
                      <div class="my-3 text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="setStatusWalk(item, 1)"
                          :color="getStatus(null)"
                          dark
                          >Pågående</v-btn
                        >
                      </div>
                      <div class="text-center">
                        <v-btn
                          rounded
                          width="120px"
                          @click="setStatusWalk(item, 2)"
                          :color="getStatus(true)"
                          dark
                          >Godkänd</v-btn
                        >
                      </div>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </td>
              <td>
                <v-icon medium class="mr-2 pa-2" @click="editItem(item), getValues()"
                  >mdi-pencil</v-icon
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table
          :search="search"
          :headers="headerWalk"
          :items="tableWalkExpItems"
          :items-per-page="20"
          :footer-props="footerProps"
          class="elevation-1"
          v-else
        >
          <template v-slot:top>
            <v-row class="d-xl-flex align-start mx-5">
              <v-col cols="12">
                <h2>Vandringar</h2>
              </v-col>
            </v-row>
            <v-row class="d-xl-flex align-center mx-5">
              <v-col cols="9">
                <v-text-field v-model="search" label="Search"></v-text-field>
              </v-col>
              <v-col cols="3">
                <button class="buttoncreate" @click="newWalk()">
                  Ny vandring
                </button>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="{ item }">
            <tr @click="redirectPageWithValueToHikingExperiences(item)
                " style="cursor: pointer">
              <td>{{ item.name }}</td>
              <td>{{ item.countyName }}</td>
              <td>{{ item.badgeName }}</td>
              <td>{{ item.expTypeName }}</td>
              <td>
                <v-menu :nudge-width="40" offset-y>
                  <template v-slot:activator="{}">
                    <v-btn
                      style="width: 130px"
                      :color="getStatus(item.isApproved)"
                      dark
                    >
                      <strong> {{ getStatustext(item.isApproved) }} </strong>
                    </v-btn>
                  </template>
                </v-menu>
              </td>
              <td>
                <v-icon medium class="mr-2 pa-2" @click="redirectPageWithValueToHikingExperiences(item)"
                  >mdi-pencil</v-icon
                >
              </td>
            </tr>
          </template>
          <template v-slot:item.isApproved="{ item }">
            <v-text v-model="item.isApproved"></v-text>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon medium class="mr-2 pa-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>


          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { HTTP } from "@/plugins/backendAPI";
import router from "../router";

export default {
  props:['asd'],
  data() {
    return {
      dialog: false,
      search: "",
      footerProps: { "items-per-page-options": [50, 100] },
      headers: [
        { text: "Namn", value: "name" },
        { text: "Kommun/Vandring", value: "countyName" },
        { text: "Teknik/Badge", value: "badgeName" },
        { text: "Skapare", value: "creatorName" },
        { text: "Status", value: "isApproved" },
        { text: "Redigera", value: "action", sortable: false, width: 130 },
      ],
      headerWalk: [
        { text: "Namn", value: "name" },
        { text: "Upplevelse typ", value: "expTypeName" },
        { text: "Status", value: "isApproved" },
        { text: "Redigera", value: "action", sortable: false, width: 130 },
      ],
      creatorHeader: [
        { text: "Namn", value: "name" },
        { text: "Kommun", value: "countyName" },
        { text: "Skapare", value: "creatorName" },
        { text: "Upplevelese typ", value: "expTypeName" },
        { text: "Status", value: "isApproved" },
        { text: "Redigera", value: "action", sortable: false, width: 130 },
      ],
      allTypes: [],
      activeOnWalk: null,
      isDisabled: false,
      isDisabledTime: false,
      step: 0, 
      loading: null,
      loadingSave: null,
      tableItems: [],
      tableWalkExpItems: [],
      uniqueData: null,
      showAlert: false,
      valid: null, 
      dialoglists: {},
      AdminApproved: '',
      detailsdialog: false,
      rules: {
        required: (value) => !!value || "",
        min: (v) => v.length < 500 || "Max 500 bokstäver",
      },
    };
  },

  mounted(){
    this.getitems(this.$store.getters.id);
    this.getexpwalk(this.$store.getters.id)
  },
  created() {
    setTimeout(() => { 
      this.getitems(this.$store.getters.id);
      this.getexpwalk(this.$store.getters.id)
    }, 1000)



  },
  

  computed:{
  buttonActive(day) {
    return day.isActive ? 'success' : 'primary'
  },
  dates() {
    return this.days.map(day => day.date);
    },
  attributes() {
    return this.dates.map(date => ({
        highlight: true,
        dates: date
      }));
    },

  tab: {
    set (tab) {
      this.$router.replace({ query: { ...this.$route.query, tab } })
    },
    get () {
      return this.$route.query.tab
    }
  },
    
  },
  methods: {
    customSearch (value, search, item) {
          return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()))
    },

    activeWalkSearcher(){
      const expKeys = Object.keys(this.experiences);
        const tableKeys = Object.keys(this.tableItems);
        this.tableItems.activeOnWalk = null;
        for (let key_Index = 0; key_Index < expKeys.length; key_Index++) {
          for (let tbl_Index = 0; tbl_Index < tableKeys.length; tbl_Index++) {
            const tbl = this.tableItems[tbl_Index];
            const exp = this.experiences[key_Index];
          if(tbl.id === exp.experienceId){
            // tbl.activeOnWalk = "(V)";
            tbl.activeOnWalk = true
          }
          if(tbl.activeOnWalk == false){
            tbl.activeOnWalk = "";
          }
          } 
        }
    },
    redirectPageWithValue(item){
      router.push({name: 'ModifyExperience', params: {id: item.id}, props: {item}})
    },

    redirectPageWithValueToHikingExperiences(item){
      router.push({name: 'HikingExperiences', params: {id: item.id}, props: {item}})
    },
    showstatusdialog(item) {
      this.selecteditem = item;
      // this.statusdialog = true
    },
    getStatus(item) {
      switch (item) {
        case false:
          return "red";
        case null:
          return "orange";  
        case true:
          return "green";
        case 1:
          return "purple";
      }
    },
    getStatustext(item) {
      switch (item) {
        case false:
          return "Ej godkänd";
        case null:
          return "Pågående";
        case true:
          return "Publicerad";
        case 1:
          return "Sparad";
      }
    },
    setStatus(item, status) {
      if (status === 1 || status === 3) {
        item.isApproved = null;
      } else if (status === 2) {
        item.isApproved = true;
      }
      HTTP.post("/experience/approvedexp", {
        id: item.id,
        creatorId: item.creatorId,
        isApproved: item.isApproved,
      })
        .then((response) => {
          this.AdminApproved = response.data
        })
        .catch((e) => {
          this.$store.dispatch(
            "showError",
            "Error when setting status: " + e.message
          );
        });
    },
    deniedExperience(item, status) {
      if (status === 0) {
        this.$root.$deniedmessage
          .open(
            "Du kommer nu neka denna uppelevelse",
            "Välj ett alternativ eller skriv ett meddelande till skaparen",
            { color: "red" }
          )
          .then((response) => {
            if (response) {
              item.isApproved = false;
              HTTP.post("/experience/approvedexp", {
                id: item.id,
                creatorId: item.creatorId,
                isApproved: item.isApproved,
                expDeniedMessage: response[0],
                expDeniedOption: response[2],
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch(
              "showError",
              "Error when setting status: " + e.message
            );
          });
      }
    },

        setStatusWalk(item, status) {
      if (status === 1 || status === 3) {
        item.isApproved = null;
      } else if (status === 2) {
        item.isApproved = true;
      }
      HTTP.post("/experience/approvedwalk", {
        id: item.id,
        creatorId: item.creatorId,
        isApproved: item.isApproved,
      })
        .then((response) => {
          this.AdminApproved = response.data
        })
        .catch((e) => {
          this.$store.dispatch(
            "showError",
            "Error when setting status: " + e.message
          );
        });
    },
    deniedExperienceWalk(item, status) {
      if (status === 0) {
        this.$root.$deniedmessage
          .open(
            "Du kommer nu neka denna uppelevelse",
            "Välj ett alternativ eller skriv ett meddelande till skaparen",
            { color: "red" }
          )
          .then((response) => {
            if (response) {
              item.isApproved = false;
              HTTP.post("/experience/approvedwalk", {
                id: item.id,
                creatorId: item.creatorId,
                isApproved: item.isApproved,
                expDeniedMessage: response[0],
                expDeniedOption: response[2],
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch(
              "showError",
              "Error when setting status: " + e.message
            );
          });
      }
    },

   getitems(userId) {
      HTTP.get("/experience/get/" + userId).then((response) => {
        HTTP.get("/experience/getExpWalk").then((exp) =>{
        this.experiences = exp.data
      })
        this.tableItems = response.data
        this.activeWalkSearcher();
      });
      
    },

    getexpwalk(userId) {
      HTTP.get("/experience/get/expwalk/" + userId).then((response) => {
        this.tableWalkExpItems = response.data
      });
    },
    newitem() {
      router.push({name: 'ModifyExperience', params: {id: 0}})
    },

    newWalk() {
      router.push({name: 'HikingExperiences', params: {id: 0}})
    },



    editItem(item) {
      this.editedItem = Object.assign({}, item);
      HTTP.get("/experience/gettypes").then((response) => {
        this.dialoglists = response.data;
      });
      this.dialog = true;
      this.loading = null;
      
    },
   deleteItem() {
      const item = this.editedItem
      this.dialog = false;
      var self = this;
      this.$root.$confirm
        .open("Radera upplevelse", "Är du säker?", { color: "red" })
        .then((response) => {
          if (response) {
            HTTP.post("/experience/delete", { id: item.id }).then(() => {
              self.tableItems.splice(self.tableItems.indexOf(item), 1);
              this.getitems(this.$store.getters.id);
            });
          }
        });
    },
    pictureurl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + "/shared/bilder/" + picturename;
    },
    cancel() {
        this.dialog = false;
        this.$refs.form.resetValidation()
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}
/* #map {
    height: 50vh;
  } */
.buttoncreate {
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

.buttoncreate:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}

.closeexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #d2d2d2;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #a3a3a4;
}

.closeexp:active {
  box-shadow: 0 5px #d2d2d2;
  transform: translateY(4px);
}

.saveexp {
  padding: 5px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #6abf62;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #4ca645;
}

.saveexp:active {
  box-shadow: 0 5px #6abf62;
  transform: translateY(4px);
}

.uploadbuttoncreator {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: orange;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px rgb(214, 141, 4);
}
.progressStyle {
  position: absolute;
  z-index: 100;
  top: 35%;
  left: 45%;
}
.saveexp:active {
  box-shadow: 0 5px orange;
  transform: translateY(4px);
}

/* Info icon */

.relativeDiv {
  position: relative;
}

.help-tip {
  transform: translate(-50%, -50%);
  margin: 12px 15px;
  text-align: center;
  border: 2px solid #444;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 21px;
  cursor: default;
  z-index: 1;
}

.help-tip:before {
  content: "i";
  font-family: sans-serif;
  font-weight: normal;
  color: rgb(17, 17, 17);
}

.help-tip:hover p {
  display: block;
  transform-origin: -100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}



.help-tip p {
  display: none;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-align: left;
  background-color: #ffffff;
  padding: 12px 16px;
  width: 300px;
  height: auto;
  position: absolute;
  top: -370px;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 3px;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.1);
  color: #37393d;
  font-size: 12px;
  line-height: 18px;
  z-index: 99;
}
.label-color /deep/ label {
  color: red;
}

.help-tip p a {
  color: #067df7;
  text-decoration: none;
}

.help-tip p a:hover {
  text-decoration: underline;
}

/* Prevents the tooltip from being hidden */
.help-tip p:after {
  width: 10px;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}

.approvedAndCreatedBy {
  text-align: right; 
  padding-right: 10px; 
  margin-bottom: 0px;
}

.main {
    padding: 45px 50px;
  }
  .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .map-holder {
    width: 65%;
  }
  #map {
    height: 25vh;
    width: 28vh;
  }

/* CSS animation */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
</style>
