import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import ApexCharts from 'apexcharts'
import { TimePicker } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueApexCharts from 'vue-apexcharts'
import '@/plugins/apexcharts'
import '@/plugins/antdvpicker'
import './assets/app.scss'

Vue.config.productionTip = false


new Vue({
  router,
  store,
  ApexCharts,
  TimePicker,
  VueApexCharts,
  vuetify,
  render: h => h(App)
}).$mount('#app')
