<template>
  <v-main>
    <v-btn style="float: right; margin: 15px;" to="/home" class="closeexp adminlargebutton"
      >Hem</v-btn
    >
    <v-container style="max-width: 600px;">
      <div class="py-8 text-center">
        <img src="./../assets/Hubblaloggo.png" class="headimg"/>
      </div>
      <div>
        <p>
          <strong>Vill du bli skapare av upplevelser i Hubbla?</strong> <br> <br>
          Alla är välkomna att göra eget innehåll till Hubbla. Det kan vara film, bilder, ljud, musik och till och med AR. Innan du skapar och lägger upp en upplevelse finns det vissa saker som du behöver tänka på,
           t ex att saker inte ska vara för långa, att inte visa saker som är kopplat till en person till exempel film och att det kan finnas saker med upphovsrätt som man inte alls får dela. <br> <br>
          När du har skickat in något som du har gjort så kommer en admin att se över innehållet innan det publiceras. <br> <br>
          Välkommen att bli en skapare, så får du veta mer! <br> <br>
        </p>
      </div>
      <div style="text-align: center;">
                      <v-btn
                      to="/login"
                        class="loginbtncr" text
                        >Klicka här om du redan har ett Skaparkonto</v-btn
                      >
      </div>
      <v-layout align-center justify-center mt-5>
            <v-card class="px-4 rounded-lg">
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit.prevent="Register">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.firstname"
                        :rules="[rules.required]"
                        placeholder="Förnamn"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.lastName"
                        :rules="[rules.required]"
                        placeholder="Efternamn"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.regionId"
                        placeholder="Region"
                        :items="dialoglists.regions"
                        :rules="[rules.required]"
                        item-text="regionName"
                        item-value="id"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.email"
                        :rules="[rules.required]"
                        placeholder="E-mail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpassword ? 'text' : 'password'"
                        placeholder="Lösenord"
                        hint="At least 4 characters"
                        counter
                        @click:append="showpassword = !showpassword"
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        class="loginbtncr adminlargebutton"
                        @click="Register()"
                        >Registrera</v-btn
                      >
                      <v-btn style="float: right;" text to="/integritypolicy" class="integrate">Integritetsmeddelande</v-btn>
                    </v-col>
                  </v-row>
                  <v-alert v-if="info" type="success">{{ info }}</v-alert>
                </v-form>
              </v-card-text>
            </v-card>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";

export default {
  name: "Login",
  data() {
    return {
      userinfo: {
        email: "",
        password: "",
        usertype: "",
      },
      info: "",
      editedItem: {},
      tableItems: [],
      valid: null,
      dialog: true,
      tab: 0,
      dialoglists: {},
      tabs: [
        { name: "Logga in", icon: "mdi-account" },
        { name: "Skapa konto", icon: "mdi-account-outline" },
      ],
      loading: false,
      rules: {
        required: (value) => !!value || "",
        min: (v) => v.length >= 4 || "Minst 4 tecken",
      },
      showpassword: false,
    };
  },
  created() {
    this.getRegions();
  },
  methods: {
    checkreturn(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    getRegions() {
      HTTP.get("/token/getregions").then((response) => {
        this.dialoglists = response.data;
      });
    },
    newitem() {
      this.editedItem = {
        id: 0,
        firstname: null,
        lastname: null,
        regionId: null,
        email: null,
        password: null,
        isAdmin: true,
      };
      this.editItem(this.editedItem);
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.showpassword = false;
      this.dialog = true;
    },
    Register() {
      var self = this;
      this.$refs.form.validate();
      if(!this.valid) {
        this.$root.$okconfirm
        .open("", "Vänligen fyll i de rödmarkerade fälten i formuläret", { color: "red" })
        return
      }
      HTTP.post("/token/register/creator", self.editedItem)
        .then((response) => {
          if (self.editedItem.id == 0) {
            self.editedItem.id = response.data;
            self.tableItems.push(this.editedItem);
          }
          this.info =
            "Grattis! Du har nu skapat ett konto. Du kommer nu skickas till logga in sidan.";
            setTimeout( () => this.$router.push({ path: '/login'}), 5000);
            
        })
        .catch((e) => {
          self.$store.dispatch("showError", "Fel vid sparning: " + e.response.data);
          this.$refs.form.Register();
        });
    },
  },
};
</script>

<style lang="css">
.loginbtncr {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background: #69c5e4;
  background-color: #69c5e4 !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px #34aacb;
}

.loginbtncr:active {
  box-shadow: 0 5px #69c5e4;
  transform: translateY(4px);
}

.h5headline {
  color: #7030a0;
  font-size: 20px;
  font-weight: bold;
}
p,
li {
  color: black;
  font-size: 16px;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 30px;
  }
}
</style>

