<template>
  <div>
  <div style="text-align: right; margin: 15px 15px 0px 0px">
    <v-btn
      to="/home"
      class="closeexp adminlargebutton"
      >Hem</v-btn
    >
  </div>

    <div class="text-center">
      <img src="./../assets/Hubblaloggo.png" class="headimg" />
    </div>
    <div class="container">
      <div class="headlinehubbla">
        <h5>Integritetsmeddelande</h5>
        <p>
          Region Västmanland behandlar personuppgifter inom alla verksamheter.
          Bestämmelser om personuppgiftsbehandling finns i
          Dataskyddsförordningen och Dataskyddslagen. <br><br>Vad är en personuppgift? <br>
          En personuppgift är all typ av information som kan identifiera en
          levande person, till exempel ett personnamn, personnummer,
          kontaktuppgifter, ett identifikationsnummer, en lokaliseringsuppgift,
          ip-adress eller bildupptagning. <br><br> Vilka personuppgifter behandlar vi och
          varför? <br> De personuppgifter som vi behandlar är användarnamn.
          e-postadress, födelsedatum och geografiska besökspunkter och vi
          behandlar dem för att du ska kunna använda appen Hubbla. Den lagliga
          grund som vi stöder oss på för att behandla dina personuppgifter är
          Allmänt intresse. Du kan själv välja att radera ditt konto,
          alternativt kommer ditt konto och dina personuppgifter att raderas
          efter 2 års inaktivitet. <br><br>Skydda av dina personuppgifter <br> Vi skyddar
          dina personuppgifter genom en kombination av tekniska och
          organisatoriska lösningar. Användarnamn och lösenord krävs för att
          kunna logga in i appen. Inloggning och specifik behörighet krävs för
          tillgång till databaser som hanterar personuppgifter. Anställda hos
          oss och vår leverantör ska följa våra policys, riktlinjer och rutiner
          för dataskydd. <br><br> Överföring av information <br> Vi och vår leverantör sparar
          alla personuppgifter inom EU och för inte vidare några personuppgifter
          till extern part.
        </p>
        <h5>Din rättigheter</h5>
        <div>
          <ul>
            <li>
              Du har rätt att få information om vilka personuppgiftsbehandlingar
              som vi gör.
            </li>
            <li>
              Du har rätt att begära ett registerutdrag där du kan se vilka
              personuppgiftsbehandlingar vi har om just dig.
            </li>
            <li>
              Du har rätt att begära rättning om vi har felaktigheter
              registrerade om dig.
            </li>
            <li>Du har rätt att åberopa rätten att bli glömd.</li>
            <li>
              Du har rätt till dataportabilitet (rätt att få dina
              personuppgifter flyttade) av personuppgifter som du själv
              tillhandahållit eller som genererats av dina
              handlingar/aktiviteter.
            </li>
            <li>
              Du har rätt att åberopa begränsning i behandlingen av dina
              personuppgifter.
            </li>
            <li>
              Du har rätt att invända mot en personuppgiftsbehandling och vi
              kommer då att upphöra med behandlingen under tiden ärendet utreds.
            </li>
            <li>
              Du har rätt att framföra klagomål till tillsynsmyndigheten över
              behandlingar som utförs av oss. Det kan du göra på www.imy.se.
            </li>
          </ul>
        </div>
        <p>
          För mer information om dataskydd, se Integritetskyddsmyndighetens
          (IMY) webbplats.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  methods: {
    goBack () {
     return this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
h5 {
  color: #7030a0;
  font-size: 20px;
  font-weight: bold;
}
p,
li {
  color: black;
  font-size: 16px;
  font-weight: normal;
}

span {
  font-size: 18px;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 30px;
  }
}
</style>